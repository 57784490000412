<template>
  <BaseFinancialStatement docType="INCOME" />
</template>

<script>
import BaseFinancialStatement from '@/component/BaseFinancialStatement.vue'

export default {
  name: 'IncomeList',
  components: { BaseFinancialStatement },
}
</script>
